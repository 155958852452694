<template>
  <v-col cols="12" md="3" sm="12">
    <v-card :loading="loading" class="mx-auto" height="180">
      <v-card-text class="text-h6 font-weight-black"> Matriculas EJA</v-card-text>

      <v-row>
        <v-col cols="7">
          <v-card-text class="text-h4 font-weight-black">
            {{ qtd ?? 0 }}
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "MatriculasEjaCard",
  async mounted() {
    await this.getQtd();
  },
  data() {
    return {
      qtd: 0,
      loading: true,
    };
  },
  methods: {
    async getQtd() {
      try {
        this.qtd = await this.$services.matriculasService.qtdEja();
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
div.chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
