import Vue from "vue";
import ETimePicker from "@/components/ETimePicker.vue";
import ELabel from "@/components/ELabel.vue";
import ESnippetMatricula from "@/components/Matriculas/ESnippetMatricula.vue";
import EModalTransferirMatricula from "@/components/Matriculas/EModalTransferirMatricula.vue";
import ESituacaoParcela from "@/components/Parcelas/ESituacaoParcela.vue";
import EUserAvatar from "@/components/Users/EUserAvatar.vue";
import EUserRole from "@/components/Users/EUserRole.vue";
import EUserSituacao from "@/components/Users/EUserSituacao.vue";
import EModal from "@/components/EModal.vue";
import EModalDinamic from "@/components/EModalDinamic.vue";
import ELoader from "@/components/ELoader.vue";
import EAutocomplete from "@/components/EAutocomplete.vue";
import EDatePicker from "@/components/EDatePicker.vue";
import EModalInfo from "@/components/EModalInfo.vue";
import ESenhaFracaAlert from "@/components/ESenhaFracaAlert.vue";
import EChangePasswordModal from "@/components/EChangePasswordModal.vue";
import ESelecionarFranquiaModal from "@/components/ESelecionarFranquiaModal.vue";
import EModalSelecionarGestao from "@/components/EModalSelecionarGestao.vue";
import EmodalConfirm from "@/components/EmodalConfirm.vue";
import EFranquiaSnippetCard from "@/components/Franquias/EFranquiaSnippetCard.vue";
import ESituacaoAula from "@/components/Aulas/ESituacaoAula.vue";
import ESituacaoAulaEletiva from "@/components/Aulas/ESituacaoAulaEletiva.vue";
import EDialogSearchClassIntervalDate from "@/components/Aulas/EDialogSearchClassIntervalDate.vue";
import EModalSelectMonths from "@/components/EModalSelectMonths.vue";
import EModalActivatorAula from "@/components/Aulas/EModalActivator.vue";
import EModalDuplicarAula from "@/components/Aulas/EModalDuplicarAula.vue";
import EModalSelectMonthAula from "@/components/Aulas/EModalSelectMonthAula.vue";
import EModalSelectMonthAulaEletiva from "@/components/Aulas/EModalSelectMonthAulaEletiva.vue";

import EModalExportarAula from "@/components/Aulas/EModalExportarAula.vue";
import EDrawerListarMatriculas from "@/components/Alunos/EDrawerListarMatriculas.vue";
import MainTemplate from "@/Pages/Templates/MainTemplate/index.vue";
import ELeftMenu from "@/components/ELeftMenu.vue";
import ETipoAulaSnippet from "@/components/Aulas/ETipoAulaSnippet.vue";
import EResumoAulasSnippet from "@/components/Aulas/EResumoAulasSnippet.vue";
import EResumoAvisosSnippet from "@/components/Aulas/EResumoAvisosSnippet.vue";
import EQuantidadeTipoDeAulas from "@/components/Aulas/EQuantidadeTipoDeAulas.vue";
import EDrawerMotivoAula from "@/components/Aulas/EDrawerMotivoAula.vue";
import ENotaInput from "@/components/ENotaInput.vue";
import ESituacaoNota from "@/components/ESituacaoNota.vue";
import EResultadoSemestral from "@/components/EResultadoSemestral.vue";
import EPaginate from "@/components/EPaginate.vue";
import EResultadoAnual from "@/components/EResultadoAnual.vue";
import EEvento from "@/components/Professores/EEvento.vue";
// Métricas
import ETurmasDashboard from "@/components/Turmas/Line.vue";
import EBarTurmasDashboard from "@/components/Turmas/Bar.vue";
// home
import MatriculasCard from "@/components/Home/MatriculasCard.vue";
import MatriculasEdInfantilCard from "@/components/Home/MatriculasEdInfantilCard.vue";
import MatriculasAnosIniciaisCard from "@/components/Home/MatriculasAnosIniciaisCard.vue";
import MatriculasAnosFinaisCard from "@/components/Home/MatriculasAnosFinaisCard.vue";
import MatriculasEjaCard from "@/components/Home/MatriculasEjaCard.vue";
import UnidadesCard from "@/components/Home/UnidadesCard.vue";
import TurmasCard from "@/components/Home/TurmasCard.vue";
import TaxaOcupacaoCard from "@/components/Home/TaxaOcupacaoCard.vue";
import FaixaEtariaCard from "@/components/Home/FaixaEtariaCard.vue";
import ZonaCard from "@/components/Home/ZonaCard.vue";
import GeneroCard from "@/components/Home/GeneroCard.vue";
import DeficientesCard from "@/components/Home/DeficientesCard.vue";
import QuantidadeProfessoresCard from "@/components/Home/QuantidadeProfessoresCard.vue";
import ProfessoreGeneroCard from "@/components/Home/ProfessorGeneroCard.vue";
import ProfessorDeficientesCard from "@/components/Home/ProfessorDeficientesCard.vue";
import ProfessorVinculoCard from "@/components/Home/ProfessorVinculoCard.vue";
import MatriculasPorUnidade from "@/components/Home/MatriculasPorUnidade.vue";
import AulasLancadas from "@/components/Home/AulasLancadas.vue";
import AulasConfirmadasLancadas from "@/components/Home/AulasConfirmadasLancadas.vue";
import TaxaEvasao from "@/components/Home/TaxaEvasao.vue";

Vue.component("e-situacao-parcela", ESituacaoParcela);
Vue.component("e-time-picker", ETimePicker);
Vue.component("e-label", ELabel);
Vue.component("e-snippet-matricula", ESnippetMatricula);
Vue.component("e-user-avatar", EUserAvatar);
Vue.component("e-user-role", EUserRole);
Vue.component("e-user-situacao", EUserSituacao);
Vue.component("e-modal", EModal);
Vue.component("e-modal-confirm", EmodalConfirm);
Vue.component("e-loader", ELoader);
Vue.component("e-autocomplete", EAutocomplete);
Vue.component("e-date-picker", EDatePicker);
Vue.component("e-senha-fraca-alert", ESenhaFracaAlert);
Vue.component("e-change-password-modal", EChangePasswordModal);
Vue.component("e-selecionar-franquia-modal", ESelecionarFranquiaModal);
Vue.component("e-selecionar-gestao-modal", EModalSelecionarGestao);
Vue.component("e-franquia-snippet-card", EFranquiaSnippetCard);
Vue.component("e-situacao-aula", ESituacaoAula);
Vue.component("e-dialog-search-aulas-interval-date", EDialogSearchClassIntervalDate);
Vue.component("e-modal-activator-aula", EModalActivatorAula);
Vue.component("e-modal-duplicar-aula", EModalDuplicarAula);
Vue.component("e-modal-select-month-aula", EModalSelectMonthAula);
Vue.component("e-modal-select-month-aula-eletiva", EModalSelectMonthAulaEletiva);

Vue.component("e-select-months-modal", EModalSelectMonths);
Vue.component("e-modal-exportar-aula", EModalExportarAula);
Vue.component("main-template", MainTemplate);
Vue.component("e-left-menu", ELeftMenu);
Vue.component("e-tipo-aula-snippet", ETipoAulaSnippet);
Vue.component("e-resumo-aulas-snippet", EResumoAulasSnippet);
Vue.component("e-resumo-avisos-snippet", EResumoAvisosSnippet);
Vue.component("e-quantidade-tipos-aulas", EQuantidadeTipoDeAulas);
Vue.component("e-nota-input", ENotaInput);
Vue.component("e-situacao-nota", ESituacaoNota);
Vue.component("e-resultado-semestral", EResultadoSemestral);
Vue.component("e-resultado-anual", EResultadoAnual);
Vue.component("e-evento", EEvento);
Vue.component("e-paginate", EPaginate);
Vue.component("e-drawer-motivo-aula", EDrawerMotivoAula);
Vue.component("e-drawer-listar-matriculas", EDrawerListarMatriculas);
Vue.component("e-modal-transferir-matricula", EModalTransferirMatricula);
Vue.component("e-modal-dinamic", EModalDinamic);
Vue.component("e-modal-info", EModalInfo);
Vue.component("e-situacao-aula-eletivas", ESituacaoAula);

// Métricas
Vue.component("e-dashboard-turma", ETurmasDashboard);
Vue.component("e-bar-dashboard-turma", EBarTurmasDashboard);

// home
Vue.component("e-matriculas-card", MatriculasCard);
Vue.component("e-matriculas-ed-infantil-card", MatriculasEdInfantilCard);
Vue.component("e-matriculas-anos-iniciais-card", MatriculasAnosIniciaisCard);
Vue.component("e-matriculas-anos-finais-card", MatriculasAnosFinaisCard);
Vue.component("e-matriculas-eja-card", MatriculasEjaCard);
Vue.component("e-unidades-card", UnidadesCard);
Vue.component("e-turmas-card", TurmasCard);
Vue.component("e-taxa-ocupacao-card", TaxaOcupacaoCard);
Vue.component("e-faixa-etaria-card", FaixaEtariaCard);
Vue.component("e-zona-card", ZonaCard);
Vue.component("e-genero-card", GeneroCard);
Vue.component("e-deficientes-card", DeficientesCard);
Vue.component("e-quantidade-professores-card", QuantidadeProfessoresCard);
Vue.component("e-professor-genero-card", ProfessoreGeneroCard);
Vue.component("e-professor-deficientes-card", ProfessorDeficientesCard);
Vue.component("e-professor-vinculo-card", ProfessorVinculoCard);
Vue.component("e-matriculas-unidade-card", MatriculasPorUnidade);
Vue.component("e-aulas-lancadas-card", AulasLancadas);
Vue.component("e-aulas-confirmadas-lancadas-card", AulasConfirmadasLancadas);
Vue.component("e-taxa-evasao-card", TaxaEvasao);
